.btn {
    border-radius: 8px;
}

.btn-bg-blue {
    background-color: #99649C;
    color: #fff;
    transition: 0.3s ease;
}

.btn-bg-red {
    background-color: #ff4d4f;
    color: #fff;
    transition: 0.3s ease;
}

.btn-outline-my {
    background-color: #fff;
    color: #000000;
    opacity: 0.2;
    border-color: #000000;
    transition: 0.3s ease;
}

.btn-outline-gray {
    background-color: #fff;
    color: #858585;
    border-color: #858585;
    transition: 0.3s ease;
}

.btn-bg-blue:hover, .btn-bg-red:hover {
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.14),0 1px 7px 0 rgba(0,0,0,.12),0 3px 1px -1px rgba(0,0,0,.2);
    color: #fff;
   
}

.btn-outline-my:hover {
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.14),0 1px 7px 0 rgba(0,0,0,.12),0 3px 1px -1px rgba(0,0,0,.2);
    color: #000000;
    opacity: 1.0;
}

.btn-outline-gray:hover {
    box-shadow: 0 3px 3px 0 rgba(0,0,0,.14),0 1px 7px 0 rgba(0,0,0,.12),0 3px 1px -1px rgba(0,0,0,.2);
    color: #858585;
}

.btn.disabled, .btn:disabled {
    /*opacity: 0.5;*/
    filter: blur(2px);
}