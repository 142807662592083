.layout {
    position: relative;
    z-index: 1000;
    min-height: 100vh;
    min-width: 100vw;
}

.layout_wrapper {
    display: flex;
    width: 100%;
    height: calc(100vh - var(--navBarHeight));
    /*height: 100%;*/
    justify-content: flex-start;
    align-items: flex-start;
}