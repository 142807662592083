.sideBar {
    min-width: 220px;
    min-height: calc(100vh - var(--navBarHeight));
    padding:18px;
    border-right: 1px solid var(--blueGray100);
}

.sideBar_title {
    margin-top: 32px;
    margin-left: 40px;
    font-weight: 700;
    font-size: x-large;
}

.sideBar_el {
    margin-top: 16px;
    color: black;
    text-decoration: none;
    display: flex;
    gap: 11px;
    border-radius: 24px;
    padding: 4px 8px;
}

.sideBar_el span {
    min-height: 24px;
    display: flex;
    align-items: center;
}

.sideBar_el_active,
.sideBar_el_active:hover {
    background: var(--blue800);
    color: var(--blueGray100);
}

