footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: var(--footerHeight);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    min-height: var(--footerHeight);
    border-top: 1px solid var(--blueGray100);
    background: var(--footerBgColor);
}

.absolute {
    position: absolute;
}

.stepper {
    height: 2px;
    background: #047857;
    left: 0;
    top: 0;
}
.stepper:after {
    content: '';
    position: absolute;
    right: -4px;
    top: 50%;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #047857;
    transform: translateY(-50%);
}
