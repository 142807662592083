.topNav {
    position: relative;
    z-index: 1001;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 11px 0;
    flex-wrap: nowrap;
    height: var(--navBarHeight);
    border-bottom: 1px solid var(--blueGray100);
}

.topNav_left,
.topNav_right {
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: 24px;
    background: var(--blueGray100);
}

.topNav_left {
    justify-content: center;
    gap: 1rem;
    margin: 0 auto;
}

.topNav_right {
    justify-content: center;
    margin-right: 1rem;
}

.topNav_el {
    color: black;
    text-decoration: none;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 11px;
    border-radius: 24px;
    padding: 0 18px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
}
.topNav_el:hover {
    color: black;
    text-decoration: none;
}

.topNav_el span {
    min-height: 24px;
    display: flex;
    align-items: center;
}

.topNav_active,
.topNav_active:hover {
    background: var(--blue800);
    color: var(--blueGray100);
}

