:root {
    --blueGray100: #F1F5F9;
    --blueGray400: #94A3B8;
    --blue800: #99649C;
    --dark: #3D424A;
}

.blueGray100 {
    color: var(--blueGray100);
}

.blueGray400 {
    color: var(--blueGray400);
}

.coolGray400 {
    color: #9CA3AF;
}

.dark {
    color: var(--dark);
}

.txt-gray {
    color: #6D7178;
}

.table-text-color {
    color: #33353B;
}

.red {
    background: red;
}
.green {
    background: green;
}
.blue {
    background: blue;
}
.yellow {
    background: yellow;
}
