.editContainer {
    position: relative;
    width: 70%;
}

.textEdit {
    width: 100%;
    min-height: 500px;
    height: 500px;
    border-top: 1px solid #D1D5DB;
    border-left: 1px solid #D1D5DB;
    border-bottom: 1px solid #D1D5DB;
    padding: 5px;
    overflow-y: scroll;
}

.legend {
    width: 30%;
    min-height: 500px;
    height: 500px;
    /*border: 1px solid black;*/
    padding: 5px;
    background: #F7F7F7;
}

.legendTag {
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

.popup {
    width: 380px;
    background: #FFFFFF;
    /*height: 130px;*/
    border-radius: 2px;
    top: 20px;
    left: 20px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.12), 0px 3px 11px rgba(0, 0, 0, 0.08);
}

.popEl {
    cursor: pointer;
    padding: 6px 24px 8px 16px;
}

.popEl:hover {
    background: var(--blueGray100);
}

#issues {
    display: none;
}

.glvrd {
    border-radius: 5px;
    padding: 0px 3px 0px 3px;
    text-decoration: underline;
    text-decoration-style: wavy;
    text-decoration-color: #DA570F;
    background-color: #FFDDCC;
    transition-duration: 0.2s;
}

.no_glvrd {
    border-radius: 0px;
    padding: 0px 0px 0px 0px;
    text-decoration: none;
    text-decoration-color: black;
    background-color: white;
}

.glvrd:hover {
    background-color: #DA570F;
}

#category {
    font-weight: bold;
    font-size: larger;
    text-align: center;
}

#skip {
    display: none;
}