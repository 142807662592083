.chartsInline {
    display: flex;
    justify-content: space-around;
}

.bar {
    width: 50%;
}

.pie {
    width: 50%;
}

.barMain {
    margin-bottom: 45px;
}
