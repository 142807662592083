.wrapper {
    padding: 2.5rem;
}

.filemanager {
    display: flex;
    width: 100%;
}

.b-radius {
    border-radius: 5px;
    box-shadow: 1px 1px 2px #d9d9d9;
}

.text-gray {
    color: #858585;
}

.form-control {
    border-radius: 8px;
}

.mb16px {
    margin-bottom: 16px;
}

/*TABLES*/
.fa {
    min-width: 24px !important;
}

.opacity-0 {
    opacity: 0;
    transition: 0.3s;
}

.opacity-1 {
    opacity: 1;
    transition: 0.3s;
}

tr:hover i.fa.fa-pencil-square-o {
    opacity: 1 !important;
}

tr:hover i.fa.fa-trash-o {
    opacity: 1 !important;
}

.table > :not(:first-child) {
    border-top: 0;
}
th {
    font-weight: normal;
}

.card {
    /*border: 1px solid rgba(0,0,0, 0.15);*/
    border: none;
    /*margin: 0 32px;*/
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 4px;
}

.minWidth-136 {
    min-width: 136px;
}

.container-fluid {
    max-width: 100vw !important;
    overflow-x: hidden !important;
}