.f-regular {
    font-weight: 400;
}

.f-medium {
    font-weight: 600;
}

.f-bold {
    font-weight: 700;
}

.text-base {
    font-size: 1rem;
}

.text-xl {
    font-size: 20px;
}

.text-2xl {
    font-size: 24px;
}

.text-lg {
    font-size: 18px;
}

.text-sm {
    font-size: 15px;
}

.text-xs {
    font-size: 13px;
}

