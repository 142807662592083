@import '~bootstrap/dist/css/bootstrap.min.css';
@import './css/buttons.css';
@import './css/common.css';
@import './css/colors.css';
@import './css/font.css';
@import './css/layout.css';
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300&display=swap');
@import './css/navBar.css';
@import './css/sidebar.css';
@import './css/footer.css';
@import './css/notfound.css';
@import './css/flowy.css';
@import './css/arch.css';
@import './css/docs.css';
@import './css/tests.css';
@import './css/tree.css';

:root {
  --navBarHeight: 54px;
  --footerHeight: 60px;
  --footerBgColor: #F3F4F6;
}

* {
  font-family: 'Fira Sans', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body, html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}


